import React from 'react';

const GoogleMap = () => {
  return (
    <div className="row">
      {/* Contenedor responsivo */}
      <div className="embed-responsive embed-responsive-16by9">
        {/* Mapa de Google */}
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.7400738642064!2d-99.2294364!3d19.509815600000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d202d9ed941e9d%3A0x5de2b91485fb66a8!2sCto.%20Medicos%2052%2C%20Cd.%20Sat%C3%A9lite%2C%2053100%20Naucalpan%20de%20Ju%C3%A1rez%2C%20M%C3%A9x.!5e0!3m2!1ses-419!2smx!4v1714082028201!5m2!1ses-419!2smx" 
          width="600" 
          height="400" 
          className="embed-responsive-item"
          style={{ border: '0' }} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default GoogleMap;
